import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot,} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../services/auth.service';
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {getUser} from "../auth.selectors";
import {map, Observable} from "rxjs";

export const rolesGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthService);
  const toastService = inject(ToastrService);
  const store = inject(Store<AppState>);
  return store.pipe(select(getUser), map((user) => {
    if (!!user) {
      const roles = route.data['roles'] as string[];
      const roleMatched = roles.find(
        (role) => authService.user?.role?.id === role
      );
      if (!roleMatched) {
        toastService.error('You are not authorized to access this page');
        authService.logout();
      }
      return !!roleMatched;
    }
    // return true is necessary because during refresh token the user is not yet available, if is not logged the auth guard will redirect to login page
    return false;
  }));
};

