import {Component, input, signal, WritableSignal} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {NgClass} from "@angular/common";


@Component({
  selector: 'st-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  standalone: true,
  imports: [
    NgClass
  ],
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  isLoadingPdf: WritableSignal<boolean> = signal(true);

  isPage = input(true);
  isActivationAccount = input(false);

  url = 'assets/pdf/privacy-policy.pdf';
  pdf: string = '';

  constructor(private toastService: ToastrService) {
  }
}
