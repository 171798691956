<div [ngClass]="{'sc-main-container md:p-4' : isPage(), 'sc-text-default': !isActivationAccount()}"
     class="text-black pr-2">

  <div [ngClass]="{'sc-card !p-2 md:!p-4': isPage()}">
    <h1 [ngClass]="{'!text-xl': isActivationAccount()}">Informativa sul trattamento dei dati personali – Sectra24</h1>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">1. Titolare del trattamento (art. 13, 1° comma, lett. a,
      GDPR)</h2>
    <p>La presente Informativa ha lo scopo di descrivere e comunicare
      le modalità di trattamento dei dati personali dei
      visitatori/utenti che utilizzano il presente applicativo di Sectra24 (“la piattaforma”). <br>
      EY S.p.A., con sede legale in Milano, Via Meravigli, 14, P.IVA 13368781004, (di seguito, per brevità,
      “EY” o “il titolare”) opera come Titolare del trattamento.<br>
      Nel rispetto della normativa privacy e sulla protezione dei dati personali di cui al Regolamento (UE) 679/2016
      (GDPR) e del D.Lgs. 196/2003 e s.m.i. (Codice Privacy), il Titolare del trattamento dei dati è tenuto ad informare
      l’interessato, cui i dati personali si riferiscono, su quali sono i dati che vengono trattati e su taluni elementi
      qualificanti il trattamento, che deve sempre avvenire con correttezza, liceità e trasparenza, tutelando la
      riservatezza e i diritti dell’interessato medesimo.
    </p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">2. Data protection officer (art. 13, 1° comma, lett. b, e 37
      GDPR)</h2>
    <p>In ragione delle attività di trattamento svolte, il Titolare ha ritenuto necessario designare, ai sensi dell’art.
      37 del GDPR, un responsabile della protezione dei dati (data protection officer o DPO) reperibile al seguente
      indirizzo PEC: <a href="mailto:dpo.italy@it.ey.com">dpo.italy&#64;it.ey.com</a></p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">3. Per quali finalità ci occorrono i dati dell’Interessato (art.
      13, 1° comma, lett. c,GDPR)</h2>
    <p>Il titolare raccoglie e/o riceve le informazioni che riguardano l’Interessato, quali:</p>
    <div class="flex py-6 justify-center">
      <div class="grid grid-cols-2 max-w-5xl gap-1">
        <span [ngClass]="{'!text-lg': !isActivationAccount()}" class="font-bold pb-2">Categoria di dati</span>
        <span [ngClass]="{'!text-lg': !isActivationAccount()}" class="font-bold pb-2">Esemplificazione delle tipologie di dati</span>
        <span class="pb-2">Dati di navigazione</span>
        <span class="pb-2">Indirizzi IP, orario della richiesta, indirizzi in notazione URI.</span>
        <span>Dati forniti dall’utente</span>
        <span>Dati anagrafici, quali nome e cognome. <br>
          Dati di contatto indirizzo e-mail, numero di telefono. <br>
          Dati di dettaglio sulla trasferta (ad esempio luogo di partenza e destinazione, data di viaggio, codice PNR,
          etc.).<br>
          Informazioni scambiate con il Contact Center.<br>
          Posizione geografica in tempo reale del dispositivo utilizzato tramite GPS se l'utente utilizza funzionalità
          basate sulla posizione e attiva i servizi di geolocalizzazione della piattaforma.
        </span>
      </div>
    </div>
    <p>Il trattamento potrà comprendere la raccolta, registrazione, organizzazione, conservazione, consultazione,
      modifica, diffusione/comunicazione, cancellazione dei dati personali.</p>
    <p>In particolare, i dati dell’Interessato saranno trattati per:</p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">a) la gestione del rapporto contrattuale </h2>
    <p>Il trattamento dei dati personali dell’Interessato avviene per dar corso alle attività preliminari e conseguenti
      alla richiesta di iscrizione anagrafica, alla gestione delle richieste di informazioni nonché per l’adempimento di
      ogni altra attività derivante dal contratto. Base giuridica di tali trattamenti è l’adempimento delle prestazioni
      contrattuali.</p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">4. Per quanto tempo vengono conservati i dati dell’Interessato?
      (art. 13, 2° comma, lett. a, GDPR)</h2>
    <p>I dati personali dell’Interessato saranno conservati per un periodo massimo di 12 mesi. Se l’Interessato esprime
      la volontà di rimuoverli, questi dati potranno essere cancellati in anticipo, prima del termine dei 12 mesi.</p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">5. Misure tecniche e organizzative (art. 32 GDPR)</h2>
    <p>La protezione dei file è garantita tramite misure di sicurezza fisica e logica. I nostri servizi rispondono agli
      standard certificati più elevati in materia di sicurezza elettronica, e tutti i dati sono crittografati. Gli
      standard di sicurezza in vigore sono descritti nel dettaglio all’interno del contratto relativo ai servizi.<br>
      Le misure comprendono la specificazione del tipo di informazioni raccolte e dello scopo specifico del trattamento,
      fornendo agli interessati copia di tutti i record in cui sono chiaramente identificabili. Inoltre, sono cancellati
      i dati dai server qualora non sussista più la motivazione per mantenerli, fatto salvo il rispetto degli obblighi
      di legge, imposti ad esempio dalle normative fiscali. Infine, i dati sono archiviati e crittografati e sono
      adottate le precauzioni necessarie per evitare data breach.<br>
      A titolo esemplificativo disponiamo delle seguenti misure tecniche:
    </p>
    <ul class="list-disc">
      <li>Server ubicati fisicamente in Italia;</li>
      <li>Hosting SLA esteso (99,95% di operatività);</li>
      <li>Architettura di rete ad alta disponibilità per garantire operatività continua;</li>
      <li>Backup giornalieri e automatici;</li>
      <li>Accesso sicuro alle risorse (firewall, anti DDoS, DMZ);</li>
      <li>Connessioni sicure tramite il protocollo SSL (Secure Socket Layer) con cifratura a 256-bit;</li>
      <li>Amministrazione del server affidata solo a personale autorizzato;</li>
      <li>Utilizzo di account individuali sul portale;</li>
      <li>Accesso al portale tramite autenticazione «indirizzo e-mail/password» o _Single Sign On_ federativo;</li>
      <li>Numero limitato di utenti con diritto di supervisione (principio _need-to-know_);</li>
      <li>Suddivisione dei dati sul personale viaggiante per filiale/società controllata (principio _need-to-know_);
      </li>
      <li>Verifica periodica delle misure di sicurezza adeguate al rischio;</li>
      <li>Verifica periodica delle banche dati presenti (Data Discovery);</li>
      <li>Procedure Data Breach;</li>
      <li>Salvataggi dei dati personali;</li>
      <li>Ripristino tempestivo della disponibilità e dell'accesso dei dati personali in caso di incidente fisico o
        tecnico;
      </li>
      <li>Verifica trasferimenti di dati personali al di fuori della UE.</li>
    </ul>
    <p>L’accesso ai dati personali inerenti ai file di localizzazione sarà consentito a un numero limitato di dipendenti
      sulla base di esigenze conoscitive (need-to-know). Questa lista è fornita su richiesta.<br>
      Si precisa sin da ora che tale accesso potrà essere consentito anche al personale incaricato dei soggetti
      sub-responsabili del trattamento che potranno accedere alla piattaforma nel rispetto degli obblighi disposti da EY
      e dalla normativa vigente.
    </p>
    <h2 [ngClass]="{'!text-lg': isActivationAccount()}">6. Quali sono i diritti dell’interessato? (art. 13, 2° comma,
      GDPR)</h2>
    <p>In relazione al trattamento dei suoi dati personali, l’interessato ha il diritto di:</p>
    <ul class="list-[lower-alpha]">
      <li>revocare il consenso al trattamento in qualsiasi momento, per tutti i trattamenti ulteriori e non necessari al
        fine dell’erogazione del contratto di servizio. Occorre evidenziare, tuttavia, che la revoca del consenso non
        pregiudica la liceità del trattamento basata sul consenso prima della revoca, così come previsto dall’art. 7,
        comma 3, del Regolamento Europeo;
      </li>
      <li>chiedere al Titolare del trattamento l’accesso ai dati personali, così come previsto dall’art. 15 del
        Regolamento Europeo;
      </li>
      <li>ottenere dal Titolare del trattamento la rettifica e l’integrazione dei dati personali ritenuti inesatti,
        anche fornendo una semplice dichiarazione integrativa, così come previsto dall’art. 16 del Regolamento Europeo;
      </li>
      <li>ottenere dal Titolare del trattamento la cancellazione dei dati personali qualora sussista anche solo uno
        dei motivi previsti dall’art. 17 del Regolamento Europeo, per tutti i trattamenti ulteriori e non necessari al
        fine dell’erogazione del contratto di servizio;
      </li>
      <li>ottenere dal Titolare del trattamento la limitazione del trattamento dei dati personali qualora ricorrano
        una delle ipotesi previste dall’art. 18 del Regolamento Europeo; per tutti i trattamenti ulteriori e non
        necessari al fine dell’erogazione del contratto di servizio;
      </li>
      <li>ricevere dal Titolare del trattamento i dati personali che la riguardano in un formato strutturato, di uso
        comune e leggibile da dispositivo automatico, nonché ha il diritto di trasmettere tali dati a un altro titolare
        del trattamento senza impedimenti, così come previsto dall’art. 20 del Regolamento Europeo;
      </li>
      <li>opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento dei dati
        personali svolto ai sensi dell’art. 6, paragrafo 1, lettere e) o f);
      </li>
      <li> non essere sottoposto a decisioni basate unicamente sul trattamento automatizzato, che producano effetti
        giuridici che la riguardino, qualora non abbia preventivamente ed esplicitamente acconsentito, così come
        previsto dall’art. 22 del Regolamento Europeo. A mero titolo esemplificativo e non esaustivo, rientra in questa
        categoria qualsiasi forma di trattamento automatizzato di dati personali teso ad analizzare o prevedere aspetti
        riguardanti le scelte di consumo e di acquisto, la situazione economica, gli interessi, l’affidabilità, il
        comportamento;
      </li>
      <li> proporre reclamo ad un’autorità di controllo, qualora ritenga che il trattamento che la riguarda violi il
        Regolamento Europeo. Il reclamo può essere proposto nello Stato membro in cui risiede abitualmente, lavora
        oppure nel luogo ove si è verificata la presunta violazione, così come previsto dall’art. 77 del Regolamento
        Europeo.
      </li>
    </ul>
    <p>Per esercitare ciascuno dei suoi diritti, può contattare il DPO di EY agli indirizzi contenuti nella presente
      informativa.</p>

    <div class="pt-10 text-sm">
      EY S.p.A.<br>
      Sede legale: Via Meravigli, 14 – 20123 Milano <br>
      Capitale Sociale € 400.000 i.v <br>
      Partita IVA 00891231003 <br>
      Numero REA 606158
    </div>
  </div>


</div>
